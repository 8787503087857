import { Link, useLocation } from 'react-router-dom'
import { Icon } from '@/shared/ui'
import style from './index.module.scss'
import { CDropdown, CInput } from '@/shared/ui/cabinet'
import React, { useEffect, useState } from 'react'
import {
    ADD_SUBACCOUNT,
    CURRENT_SUBACCOUNT_KEY,
    ISubAccountInfoDto,
    LOGOUT_DIALOG,
    useSubAccount,
} from '@/entities/cabinet'
import { RootState, useAppDispatch } from '@/store'
import {
    setCurrentSubAccount,
    setCurrentSubAccountInfo,
    setDialogs,
} from '@/store/sub_accounts'
import { useSelector } from 'react-redux'
import {
    AddSubaccountDialog,
    LogoutDialog,
    useSubaccountSearch,
    useTheme,
} from '@/features'
import classNames from 'classnames'
import { useTranslations } from 'use-intl'
import Logo from '@/shared/ui/Logo'
import { useDebounce } from '@/shared/hooks'
import { USER_INFO } from '@/shared/constants'

const ITEMS = [
    {
        title: 'main',
        url: '/cabinet',
        type: 'dashboard',
    },
    {
        title: 'workers',
        url: '/cabinet/workers',
        type: 'worker',
    },
    {
        title: 'history',
        url: '/cabinet/history',
        type: 'earnings',
    },
    {
        title: 'payout',
        type: 'payout',
        url: '/cabinet/payout',
    },
    {
        title: 'subaccounts',
        type: 'subaccounts',
        url: '/cabinet/subaccounts',
    },
    {
        title: 'observers',
        type: 'observer',
        url: '/cabinet/observers',
    },
    {
        title: 'referal',
        url: '/cabinet/referal',
        type: 'referal',
    },
]

const BOTTOM_ITEMS = [
    {
        title: 'settings',
        url: '/cabinet/settings',
        type: 'settings',
    },
    {
        title: 'support',
        url: '/cabinet/support',
        type: 'support',
    },
    {
        title: 'faq',
        url: '/faq',
        type: 'faq',
    },
    {
        title: 'logout',
        url: '/cabinet/logout',
        type: 'logout',
    },
]

function CabinetSidebar() {
    const {
        cachedSubAccounts,
        search,
        setSearch,
        subAccounts,
        currentSubAccount,
    } = useSubaccountSearch()
    const dialogs = useSelector((state: RootState) => state.subAccount.dialogs)
    const dispatch = useAppDispatch()
    const { pathname } = useLocation()
    const [isReferalAvailable, setReferalAvailable] = useState(true)
    const { currentTheme } = useTheme()
    const i18n = useTranslations('cabinet.sidebar')
    useEffect(() => {
        const userInfo = JSON.parse(
            localStorage.getItem(USER_INFO) || JSON.stringify({})
        )
        if (!userInfo?.user?.is_referral_program_available) {
            setReferalAvailable(false)
        }
    }, [])
    return (
        <aside className={style.sidebar}>
            <div className={style.sidebar__icon}>
                {currentTheme === 'dark' ? (
                    <Link to={'/'}>
                        <Logo />
                    </Link>
                ) : (
                    <Link to="/" className="logo">
                        <img
                            src={require('@/shared/images/main_logo.png')}
                            width={130}
                            alt={'main logo'}
                        />
                    </Link>
                )}
            </div>
            <div className={style.sidebar__content}>
                <CDropdown
                    options={cachedSubAccounts.map((account) => ({
                        label: account.name,
                        key: account.name,
                    }))}
                    label={i18n('subaccounts')}
                    customList={
                        <div
                            onClick={() =>
                                dispatch(
                                    setDialogs({
                                        key: ADD_SUBACCOUNT,
                                        value: true,
                                    })
                                )
                            }
                        >
                            {i18n('create')}
                        </div>
                    }
                    searchBlock={
                        <CInput
                            error={''}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={i18n('search')}
                        />
                    }
                    value={currentSubAccount}
                    onChange={(val) => {
                        localStorage.setItem(
                            CURRENT_SUBACCOUNT_KEY,
                            JSON.stringify(
                                subAccounts.find((acc) => acc.name === val) ||
                                    {}
                            )
                        )
                        dispatch(setCurrentSubAccount(val))
                        dispatch(
                            setCurrentSubAccountInfo(
                                subAccounts.find((acc) => acc.name === val)
                            )
                        )
                    }}
                />

                <div className={style.sidebar__content__wrapper}>
                    <div className={style.sidebar__content__navbar}>
                        {ITEMS.filter(
                            (item) =>
                                item.type !== 'referal' || isReferalAvailable
                        ).map((item, idx) => (
                            <Link
                                to={item.url}
                                key={idx}
                                className={
                                    item.url === pathname ? style.active : ''
                                }
                            >
                                <Icon
                                    type={item.type as any}
                                    color={
                                        currentTheme === 'dark' ? '#fff' : ''
                                    }
                                />
                                <span>{i18n(item.title)}</span>
                            </Link>
                        ))}
                    </div>
                    <div
                        className={classNames(
                            style.sidebar__content__bottomBar,
                            style.sidebar__content__navbar
                        )}
                    >
                        {BOTTOM_ITEMS.map((item, idx) => (
                            <Link
                                to={item.url}
                                key={idx}
                                onClick={(e) => {
                                    if (item.type === 'logout') {
                                        e.preventDefault()
                                        dispatch(
                                            setDialogs({
                                                key: LOGOUT_DIALOG,
                                                value: true,
                                            })
                                        )
                                    } else if (item.type === 'support') {
                                        e.preventDefault()
                                        window.open(
                                            'https://t.me/TwentyOnePool',
                                            '_blank'
                                        )
                                    }
                                }}
                                className={
                                    item.url === pathname ? style.active : ''
                                }
                            >
                                <Icon type={item.type as any} />
                                <span>{i18n(item.title)}</span>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            {dialogs[ADD_SUBACCOUNT] && <AddSubaccountDialog />}
            {dialogs[LOGOUT_DIALOG] && <LogoutDialog />}
        </aside>
    )
}

export const CabinetSidebarComponent = React.memo(CabinetSidebar)
